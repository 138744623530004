<template>
  <en-table :data="localData">
    <en-table-column label="色母编号">
      <template #default="{ row }: { row: EnospraySprayDefinitions['SprayFormulaDetailDto'] }">
        <select-maintain
          v-if="store.accessRightsHash.FORMULA_AUDIT_ENOCH && formula?.status?.code && ['NA', 'P', 'PA', 'FA'].includes(formula?.status.code)"
          v-model="row.goods"
          :ajax="{ action: 'GET /enospray/formula/goodspaint', params: (params, name) => (params.payload = { name }) }"
          :props="{
            label: (option: EnospraySprayDefinitions['SprayGoodsDto']) => `${option.workshopGoodsSpecification?.goods.serialNo}${option.workshopGoodsSpecification?.goods.name}`,
            value: '',
            disabled: (option: EnospraySprayDefinitions['SprayGoodsDto']) => Boolean(formula?.colors.find((item) => item.goods?.id === option.id))
          }"
          value-key="id"
        ></select-maintain>
        <span v-else>{{ row.goods?.workshopGoodsSpecification?.goods.serialNo }}</span>
      </template>
    </en-table-column>
    <en-table-column label="色母名称" prop="goods.workshopGoodsSpecification.goods.name"></en-table-column>
    <en-table-column label="标准用量">
      <template #default="{ row }: { row: ISprayFormulaDetailDto }">
        <en-input-number
          v-if="store.accessRightsHash.FORMULA_AUDIT_ENOCH && formula?.status?.code && ['NA', 'P', 'PA', 'FA'].includes(formula?.status.code)"
          v-model="row.weight"
          :min="0"
          :precision="2"
        ></en-input-number>
        <span v-else>{{ formatNumber(row.weight, 2) }}</span>
      </template>
    </en-table-column>
    <en-table-column label="预计用量">
      <template #default="{ row }: { row: ISprayFormulaDetailDto }">{{ formatNumber(row.planWeight, 2) }}</template>
    </en-table-column>
    <en-table-column label="累加用量">
      <template #default="{ row }: { row: ISprayFormulaDetailDto }">{{ formatNumber(row.sumWeight, 2) }}</template>
    </en-table-column>
    <en-table-column label="操作">
      <template #default="{ row }: { row: ISprayFormulaDetailDto }">
        <en-button type="primary" link @click="">删除</en-button>
      </template>
    </en-table-column>
  </en-table>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'

type ISprayFormulaDetailDto = EnospraySprayDefinitions['SprayFormulaDetailDto'] & {
  weightPercent: number
  planWeight: number
  sumWeight: number
}

export default factory({
  props: {
    formula: Object as PropType<EnospraySprayDefinitions['SprayFormulaDto']>,
    weight: Number as PropType<number>,
    data: Array as PropType<EnospraySprayDefinitions['SprayFormulaDetailDto'][]>
  },

  computed: {
    totalWeight() {
      return this.data?.reduce((totalWeight, item) => calculator.add(totalWeight, item.weight), 0) ?? 0
    },
    localData() {
      return this.data?.map((item, index, list) => {
        const weightPercent = calculator.mul(calculator.div(item.weight, this.totalWeight), 100)

        const planWeight = calculator.div(calculator.mul(weightPercent, this.weight ?? 0), 100)

        const sumWeight = index ? calculator.add(planWeight, (list[index - 1] as ISprayFormulaDetailDto).sumWeight) : planWeight

        return Object.assign({ weightPercent, planWeight, sumWeight }, item) as ISprayFormulaDetailDto
      })
    }
  }
})
</script>
